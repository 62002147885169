import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { catchError, of, throwError, timeout } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const toastrService = inject(ToastrService);

  if(req.url.includes("/varapido")){
    return next(req);
  }

  const token = authService.token.value || authService.getAuthToken();
  // Clone the request and add the authorization header
  let headers: any = {
      Authorization: `Bearer ${token}`,
  }
  if(req.url.includes('/image')){
    const body: any = req.body;
    headers['filename'] = body['name'];
  }

  //
  const shopping_id = authService.getDecodedAccessToken()?.shopping_id ?? localStorage.getItem('shopping_code');

  if(!shopping_id) 
    return throwError(() => 'Não foi possível identificar o código do shopping');

  const authReq = req.clone({
    setHeaders: headers,
    url: req.url.replace('{shopping_id}', shopping_id)
  });
  return next(authReq).pipe(
    catchError((err) => {
      if (err.status === 401) {
        if (token && !authReq.url.includes('refresh')) {
          authService.refreshToken().subscribe({
            next: (v: any) => {
              authService.saveToken(v.token);
              next(authReq);
            },
            error: (e) => {
              authService.logout();
            },
          });
        } else if (authReq.url.includes('refresh')) {
          authService.logout();
        } else if (authReq.url.includes('auth')) {
          // toastrService.error(err.error.msg || 'Erro');
        }
        return throwError(() => err);
      }
      if(err.stauts === 502 || err.status === 0){
        err.error.msg = 'Não foi possível conectar-se ao servidor. Tente novamente mais tarde.';
      }
      return throwError(() => err);
    }),
    timeout(120000)
  );
  
};
