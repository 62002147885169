import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const canMatchGuard: CanMatchFn = (route, segments) => {
  const authService = inject(AuthService);
  const role = authService.getDecodedAccessToken()?.role;
  if(!route.data || !route.data['role'])
    return true;
  if (typeof window === 'undefined') return false;

  if(route.data['role'].includes(Number(role)))
    return true;
  else
    return false;
};
